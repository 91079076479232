import { localStorageGet, localStorageRemove, localStorageSet } from '../../helpers/localstorage';
import questions from '../../../__content/questions.json';

const LOCAL_STORAGE_ARCHTYPE_KEY = 'archetype';
const LOCAL_STORAGE_ANSWERS_KEY = 'answers';

/**
 * Returns the questions from the json file
 * @returns Question[]
 */
export const getQuestions = (): Question[] => questions;

/**
 * Gets the answers from the local storage
 * @returns Answers
 */
export const getAnswers = (): Answers | null => localStorageGet(LOCAL_STORAGE_ANSWERS_KEY);


/**
 * Nukes the localstorage answers.
 * @returns void
 */
export const resetAnswers = (): void => localStorageRemove(LOCAL_STORAGE_ANSWERS_KEY);

/**
 * Answers the question and stores the answers in local storage.
 * @param id - question id
 * @param answer - Answer
 * @returns answer
 */
export const answerQuestion = (id: Question['id'], answer: Answer): Answers => {
    const answers = getAnswers() || {};
    answers[id] = answer;

    localStorageSet(LOCAL_STORAGE_ANSWERS_KEY, answers);

    return answers;
};

/**
 * Calculates the archtype by grabbing all the answers and tallying up the weighting on the answers.
 * @returns
 */
export const calculateArchtype = (): string | null => {
    const answers = getAnswers();
    const tally = {};

    if (!answers) return null;

    /** loop through answers and fill in the tally object { archtype: count } */
    Object.values(answers).forEach(({ weighting }) => {
        Object.keys(weighting).forEach((ar) => {
            if (!tally[ar]) tally[ar] = 0;

            tally[ar] += weighting[ar];
        });
    });

    // Create a tally array and sort by highest number first.
    const tallyArr = Object.keys(tally).map((a) => ({ slug: a, count: tally[a] })).sort((a, b) => b.count - a.count);

    // from the answers to the questions figure out the archetype.
    const calculatedArchType = tallyArr[0].slug;

    localStorageSet(LOCAL_STORAGE_ARCHTYPE_KEY, calculatedArchType);

    return calculatedArchType;
};

/** Gets archetype from localstorage and returns it */
export const getArchtype = (): string | null => localStorageGet(LOCAL_STORAGE_ARCHTYPE_KEY);


