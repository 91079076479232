import React from 'react';
import { graphql, navigate } from 'gatsby';
import Layout from '../../../../layouts/Layout';
import useCheckArchType from '../../../../hooks/useCheckArchType';
import MillerDisplay from '../../../../components/Typography/MillerDisplay/MillerDisplay';

import * as containStyles from '../../../../styles/contain.module.scss';

interface Props {
    data: {
      archetype: ArcheType;
    };
}

const ArchTypeThankyouPage = ({ data: { archetype } }: Props): JSX.Element => {
    /**
       * To ensure people only hit this page if they have answered.
       * if archetype isnt in storage then we'll redirect back to the homepage.
    */
    const [archType] = useCheckArchType(() => navigate('/'));

    if (!archType) return <p>Loading...</p>;

    return (
        <Layout>
            <section className={`${containStyles.contain} ${containStyles.containMedium}`}>
                <div className="grid md:grid-cols-2 gap-8">
                    <div className="space-y-4 flex flex-col justify-center">
                        <MillerDisplay tag="h1" typeset="title">Thank you, we'll be in touch</MillerDisplay>
                    </div>
                    <div className="hidden md:flex items-center">
                        <img src={archetype.imagePath} alt={archetype.name} />
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default ArchTypeThankyouPage;

export const query = graphql`
  query($id: String) {
    archetype(id: { eq: $id }) {
      name
      title
      slug
      imagePath
      contentOne
      contentTwo
    }
  }
`;
