/**
 * Checks if localStorage is available in the current browser.
 * @returns {boolean}
 */
const isLocalStorageAvailable = (() => {
    // If there is now browser window.
    if (typeof window === 'undefined') {
        return false;
    }

    // Try to add and remove an item.
    try {
        // Get the storage from the window.
        const storage = window.localStorage;

        const storageTest = '__storage_test__';
        storage.setItem(storageTest, storageTest);
        storage.removeItem(storageTest);
        return true;
    } catch (e) {
        return false;
    }
})();


/**
 * Set local storage key with value
 * @param {*} key - key to set
 * @param {*} value - value/ object etc to stringify and set
 * @returns any
 */
export const localStorageSet = <T>(key: string, value: T | unknown): void => {
    if (isLocalStorageAvailable === false) return;

    if (typeof value === 'undefined' || value === 'undefined' || value === null || value === 'null') {
        localStorageRemove(key);
        return;
    }

    window.localStorage.setItem(key, JSON.stringify(value));

    dispatchEvent(new Event('storage')); // As the storage event only fires on other tabs changing it we're adding this here
};


/**
 * Get local storage value by key
 * @param {*} key - key to retrieve
 */
export const localStorageGet = <T>(key: string): T | null => {
    if (isLocalStorageAvailable === false) return null;

    const value = window.localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
};

/**
 * Remove local storage by key
 * @param {string} key - key of the local storage to remove
 */
export const localStorageRemove = (key: string): void => {
    if (isLocalStorageAvailable === false) return;

    window.localStorage.removeItem(key);

    dispatchEvent(new Event('storage')); // As the storage event only fires on other tabs changing it we're adding this here
};
