import { useEffect } from 'react';
import { getArchtype } from '../modules/questions/questionManager';

type cb = ()=> unknown;

/**
 * Checks to see if an archetype is set in the local storage or not.
 * @param callback - runs if archetype doesnt exist
 * @returns
 */
const useCheckArcheType = (callback: cb): [string | null] => {
    const archType = getArchtype();

    useEffect(() => {
        if (!archType) {
            if (typeof callback === 'function') callback();
        }
    }, []);

    return [archType];
};

export default useCheckArcheType;
